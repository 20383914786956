import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { requestServerApi, serverApi } from '../../api/api';
import { ERROR_CODE } from '../../constants/errorCode';
const useEventLogger = () => {
  const history = useHistory();
  const source = 'learner';

  const sendInfoLog = useCallback(
    (logInfo: { message: string; payload: any }) => {
      requestServerApi<any>(() => {
        serverApi.organizationsControllerCreateInfoLog({
          source: source,
          currentPage: history.location.pathname,
          userAgent: navigator.userAgent,
          message: logInfo.message,
          payload: JSON.stringify(logInfo.payload),
          clientDate: new Date().toString(),
        });
      }).catch((err) => {
        sendErrorLog({ message: '情報ログ作成失敗', errorCode: ERROR_CODE.E_9999, error: err });
      });
    },
    [requestServerApi, serverApi],
  );
  const sendErrorLog = useCallback(
    (errorInfo: { message: string; errorCode: string; error: any }) => {
      const { error, errorCode, message } = errorInfo;

      requestServerApi<any>(() => {
        serverApi.organizationsControllerCreateErrorLog({
          source: source,
          currentPage: history.location.pathname,
          userAgent: navigator.userAgent,
          error: typeof error === 'string' ? error : Object.keys(error).length > 0 ? JSON.stringify(error) : `${error}`,
          errorCode,
          message,
          clientDate: new Date().toString(),
        });
      }).catch((err) => {
        // 無限ループを避ける
        if (errorCode === ERROR_CODE.E_9999) {
          return;
        }
        sendErrorLog({
          error: err,
          errorCode: ERROR_CODE.E_9999,
          message: `ユーザーイベントログ作成でエラー`,
        });
      });
    },
    [requestServerApi, serverApi],
  );
  return { sendErrorLog, sendInfoLog };
};
export default useEventLogger;
